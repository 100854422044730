
  @media screen and (max-width: 600px) {
    .Backup td, th {
      font-size: 0.5rem;
    }
  }
    

  @media print {
   
    button{
        display: none;
    }
    .Footer{
        display: none;
    }
    .Nav{
        display: none;
    }
    .Header{
        display: none;
    }
    .Backup {
        overflow-y: visible !important;
        height: 100%;
    }
    .App{
        max-width: 800px;
    }
    .Backup  td, .Backup th { 
        padding: 0.1em;
      }
    
}
