.Uploadbck{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: rebeccapurple; */
}
.Uploadbck .upload-input-file{
    width: 100%;
    max-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--fonts--4);
    border-radius: 1em;
}
.Uploadbck .upload-input-file::-webkit-file-upload-button{
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--fonts--4);
}
.Uploadbck .upload-input-file::before{
    content: 'Select file';
    display: inline-block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: hsl(var(--bg));
    border: 1px solid #999;
    border-radius: 1em;
    padding: 2px 4px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    /* text-shadow: 1px 1px #fff; */
    font-weight: 700;
    font-size: var(--fonts--4);
}
.Uploadbck .upload-input-file:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
.Uploadbck button.upload-btn-submit{
    font-size: var(--fonts--4);
    border: 1px solid #999;
    width: 100%;
    padding: 2px 4px;
    margin-top: 2px;
    z-index: 1;
    border-radius: 1em;
}