.Privacymodal {
    position: relative;
    opacity: 1;
    transition: all 400ms ease-in-out;
    animation: fadeIn 300ms ease-in-out;
}

.Privacymodal .btn-privacy {
    display: inline-block;
    cursor: pointer;
    padding: 0.2em 0.4em;
    letter-spacing: 1px;
    border-radius: 2px;
    text-align: center;
    background: hsl(var(--bg)) !important;
    color: hsl(var(--txt)) !important;
    font-size: var(--fonts--4);
    font-family:'Noto-Sans', 'Ubuntu';
    transition: 0.4s ease-in-out;
    box-shadow: 1px 1px 1px 2px rebeccapurple;
}

.Privacymodal .btn-privacy:hover {
    transform: translate(2px, 2px);
    box-shadow: 1px 1px 1px 0 rebeccapurple;
}

.Privacymodal .btn-privacy a {
    text-decoration: none;
}

.Privacymodal .privacy-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: hsl(var(--bg) / 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.Privacymodal .privacy-popup.active {
    visibility: visible;
    opacity: 1;
}

.Privacymodal .privacy-popup .privacy-content {
    position: relative;
    width: 90%;
    max-width: var(--max-width);
    background: hsl(var(--bg));
    color: hsl(var(--txt));
    border-radius: 4px;
    padding: 15px;
    margin: 30px auto;
    text-align: left;
    line-height: 1.5;
    overflow: auto;
    border: 3px solid hsl(var(--txt));
    /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
}

.Privacymodal .privacy-popup .privacy-content .close-wrapper{
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    width: 20px;
    height:20px;
}
.Privacymodal .privacy-popup .privacy-content .close-wrapper svg{
    width: 100%;
    height: 100%;
    color: var(--red);
}

.Privacymodal .privacy-popup .privacy-content .close-privacy:hover {
    color: var(--purpleTwo);
}

.Privacymodal .privacy-popup .privacy-content .privacy-ok-wrapper{
    width: 100%;
    display: flex;
    padding: 10px 20px 10px 0;
    justify-content: flex-end;
    align-items: center;
}
.Privacymodal .privacy-popup .privacy-content .privacy-ok-wrapper .privacy-ok-btn{
    border: none;
    font-size: var(--fonts-0);
    padding: 0.2em 0.8em;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    box-shadow: 1px 1px 1px 2px hsl(var(--txt));
}

.Privacymodal .privacy-popup .privacy-content h1 {
    font-size: var(--fonts-3);
}

.Privacymodal .privacy-popup .privacy-content h2 {
    font-size: var(--fonts-1);
}

.Privacymodal .privacy-popup .privacy-content p {
    color: hsl(var(--txt));
    line-height: 1.4;
    margin: .5rem auto;
    text-align: left;
    font-size: var(--fonts--0);
}

@keyframes faseIn {
    from {
        opacity: 0;
        min-height: 0;
    }
    to {
        opacity: 1;
        min-height: 45px;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
        min-height: 45px;
    }
    to {
        opacity: 0;
        min-height: 0px;
    }
}

