@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --yellow: #edba13;
  --orange: #f75114;
  --bluishRed: #fb00fb;
  --purple: #8f0a94;
  --blue: #142ef7;
  --green: #3a9400;
  --dark: #131313;
  --whiteMode: rgba(220, 220, 220, 1);
  --darkMode: rgba(41, 41, 41, 1);

  --max-width: 960px;

  /* HUE COLOR  */
  --_hue: 255;
  --_size: 1.5rem;
  --_radius: 0.2em;
  --_tspeed_fast: 150ms;
  --_tspeed_slow: 350ms;
  --_ttype_squish: cubic-bezier(0.86, -0.1, 0.27, 1.15);

  /* light color scheme  */
  --bg--light: var(--_hue) 80% 99%;
  --txt--light: var(--_hue) 80% 15%;
  --muted--light: var(--_hue) 20% 70%;
  --accent-light: var(--_hue) 70% 60%;
  --secondary--light: calc(var(--_hue) - 195) 70% 60%;

  /* DARK COLOR SCHEME */
  --bg--dark: var(--_hue) 80% 5%;
  --txt--dark: var(--_hue) 80% 99%;
  --muted--dark: var(--_hue) 80% 99%;
  --accent--dark: var(--_hue) 65% 50%;
  --secondary--dark: calc(var(--_hue) - 195) 65% 50%;

  /* custom color scheme  */
  --bg--custom: 220 80% 98%;
  --txt--custom: 200 80% 9%;
  --muted--custom: 210 80% 50%;
  --accent--custom: 180 65% 50%;
  --secondary--custom: 280 65% 50%;

  /* FONTS  https://utopia.fyi/ */
  --fonts--4: clamp(0.59rem, calc(0.45rem + 0.2vw), 0.76rem);
  --fonts--3: clamp(0.6rem, calc(0.6rem + 0.3vw), 0.86rem);
  --fonts--2: clamp(0.69rem, calc(0.62rem + 0.36vw), 0.96rem);
  --fonts--1: clamp(0.83rem, calc(0.73rem + 0.5vw), 1.2rem);
  --fonts-0: clamp(1rem, calc(0.86rem + 0.68vw), 1.5rem);
  --fonts-1: clamp(1.2rem, calc(1.02rem + 0.92vw), 1.88rem);
  --fonts-2: clamp(1.44rem, calc(1.19rem + 1.23vw), 2.34rem);
  --fonts-3: clamp(1.73rem, calc(1.4rem + 1.63vw), 2.93rem);
  --fonts-4: clamp(2.07rem, calc(1.64rem + 2.15vw), 3.66rem);
  --fonts-5: clamp(2.49rem, calc(1.92rem + 2.83vw), 4.58rem);
}

/* PREFERS COLOR SCHEME  */
@media (prefers-color-scheme: light) {
  :root {
    --bg: var(--bg--light);
    --txt: var(--txt--light);
    --muted: var(--muted--light);
    --accent: var(--accent-light);
    --secondary: var(--accent-light);
    color-scheme: light;
  }
}
/* PREFERS COLOR SCHEME  */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: var(--bg--dark);
    --txt: var(--txt--dark);
    --muted: var(--muted--dark);
    --accent: var(--accent-dark);
    --secondary: var(--accent-dark);
    color-scheme: dark;
  }
}

/* CUSTOM DARK THEME  */
:root[data-theme="light"] {
  --bg: var(--bg--light);
  --txt: var(--txt--light);
  --muted: var(--muted--light);
  --accent: var(--accent-light);
  --secondary: var(--accent-light);
  color-scheme: light;
}
:root[data-theme="dark"] {
  --bg: var(--bg--dark);
  --txt: var(--txt--dark);
  --muted: var(--muted--dark);
  --accent: var(--accent-dark);
  --secondary: var(--accent-dark);
  color-scheme: dark;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand-Regular.ttf"), url("./fonts/Quicksand-Bold.ttf"),
    url("./fonts/Quicksand-Medium.ttf");
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("./fonts/TitilliumWeb-Regular.ttf"),
    url("./fonts/TitilliumWeb-Bold.ttf");
}

@font-face {
  font-family: "Allison";
  src: url("./fonts/Allison-Regular.ttf");
}

body {
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "TitilliumWeb", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
  /* border: 1px solid #ccc; */
  margin: auto;
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

#root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  /* MAGIC SOLUTION  */
  flex-grow: 1;
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

/* LOADER  */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(255, 2, 255);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* PLACEHOLDER  */

::placeholder {
  color: var(--green);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--green);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--green);
}

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 7px;
  border-radius: 2px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

::-webkit-scrollbar-thumb:vertical {
  min-height: 0.1vh;
  border-radius: 2px;
  background-color: #f8c3f5;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: var(--bluishRed);
}

::-webkit-scrollbar-track-piece:hover {
  background-color: #f8f1f8;
}

/* DARK MODE  */

body[data-theme="dark"] {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Header {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Nav {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Psw {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Newpsw {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .PswPage {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Register {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Login {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .About {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Random {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}

body[data-theme="dark"] .Footer {
  background: hsl(var(--bg));
  color: hsl(var(--txt));
}
body[data-theme="dark"] .Missing a {
  color: hsl(var(--txt));
}
body[data-theme="dark"] .Backup td {
  color: hsl(var(--txt));
}
